import { FunctionComponent, useEffect, useState } from 'react';
import { useLocation, useHistory, useParams } from 'react-router';
import { AxiosResponse } from 'axios';
import { Grid, Box, CircularProgress, Typography, Button, IconButton } from '@mui/material';
import { ArrowDropUp, ArrowDropDown } from '@mui/icons-material';
import { CommonHelper, MsalLoginHelper } from '../../helpers';
import { LoginService } from '../../services';
import store, { setCurrentCredentials, setToken } from '../../store';
import debug from '../../helpers/debug';
import envar from '../../environment-variables';
import { AuthorityToken, HttpError } from '../../types';

const OAuth2Callback: FunctionComponent = ({ }) => {
  const location = useLocation();
  const history = useHistory();
  const { provider, profile } = useParams<{ provider: string, profile: string }>();
  const [errorData, setErrorData] = useState<any | null>(null);
  const [errorDetailsVisible, setErrorDetailsVisible] = useState<boolean>(false);

  /**
   * Captura los argumentos que le llegan del flujo de oauth y completa la segunda parte de la autentificación llamando al back.
   * @param search Parámetros pasados por la URL a esta página. 
   */
  const callback = async (search: string | undefined) => {
    var urlParams = new URLSearchParams(search);
    let redirect_uri = new URL(window.location.pathname, window.location.origin);

    switch (provider) {
      case "ntlm":
      case "cert":
      case "basic":
        handleLogin(
          login(provider, urlParams),
          `Impossible to login by ${provider}.`
        );
        break;

      case "msal":
        let clientInfo = urlParams.get("client_info");

        if (clientInfo) {
          let settings = await LoginService.getSettingsMsal(profile);
          let state = urlParams.get("state");

          MsalLoginHelper.grantAccessCode(
            settings.clientId,
            settings.authority,
            "User.Read profile openid",
            redirect_uri,
            state
          );
        }
        else {
          handleLogin(
            loginMsal(profile, urlParams, redirect_uri),
            `Impossible to login with Azure account (profile ${profile}).`
          );
        }
        break;

      case "ipaddress":
        handleLogin(
          loginIpAddress(urlParams),
          "Impossible to login with IP Address"
        );
        break;

      default:
        let message = `Unknown provider '${provider ?? "<null>"}'.`;
        history.replace(`/error?code=${encodeURIComponent(message)}`);
        break;
    }
  }

  /**
   * Comprueba el resultado de la acción pasada controla su éxito o fracaso.
   * @param promise acción a realizar.
   * @param error_message texto principal a mostrar en el error en caso de haberlo.
   */
  const handleLogin = async (promise: Promise<string | null>, error_message: string): Promise<void> => {
    return promise
      .then(returnPath => {
        console.log("return path", returnPath);
        if (returnPath) {
          history.replace(returnPath); // mover a la ruta indicada en el "state".
        }
        else {
          history.replace(envar.publicUrl ?? "/"); // Mover a root.
        }
      })
      .catch((err: AxiosResponse | HttpError) => {
        let errorData: HttpError | null = CommonHelper.summaryAxiosError(err as AxiosResponse) ?? (err as HttpError);

        console.error(error_message, "fetch error", errorData);
        setErrorData(errorData);
        history.push(`${location.pathname}?error=true`);
      });
  }

  /**
   * Obtiene y aplica el usuario del perfil indicado utilizando el código de un uso (access_code) que le devolvió el flujo de oauth2.
   * @param providerName Los proveedores válidos hasta la fecha son 'basic', 'ntlm', 'cert'.
   * @param urlParams De los parámetros de la URL se sacará el 'code' y el 'state'.
   * @returns Devuelve la URL a la que redireccionar.
   */
  const login = async (providerName: string, urlParams: URLSearchParams): Promise<string | null> => {
    let code = urlParams.get("code");
    let returnUrl = urlParams.get("state");

    if (code) {
      return await
        LoginService.createToken(providerName, code)
          .then(async result => {
            await performLogin(result);
            return returnUrl;
          });
    }
    else {
      let message = "Invalid callback format. Parameter 'code' not found.";
      let err: HttpError = {
        status: 400,
        statusText: "Bad Request",
        message: message,
        content: { location: history.location }
      }
      debug.error("error", message, history.location);
      throw err;
    }
  }

  /**
   * Obtiene y aplica el usuario de active directory utilizando el código de un uso (access_code) que le devolvió el flujo de oauth2.
   * @param profile La configuración de login de MSAL que debe utilizar en este login.
   * @param urlParams De los parámetros de la URL se sacará el 'code', el 'state', el 'session_state' y el 'client_info'.
   * @returns Devuelve la URL a la que redireccionar.
   */
  const loginMsal = async (profile: string, urlParams: URLSearchParams, redirect_uri: string | URL): Promise<string | null> => {
    return new Promise<string | null>(async (resolve, reject) => {
      let code = urlParams.get("code");
      let session = urlParams.get("session_state");
      let state = urlParams.get("state");
      let info = urlParams.get("client_info");

      if (code) {
        console.log("state", { profile, session, state, info });
        return await
          LoginService.createTokenMsal(profile, code, redirect_uri, state)
            .then(async result => {
              let returnUrl = (state == null ? null : state.split('|')[1]);

              await performLogin(result);
              resolve(returnUrl);
            })
            .catch(reject)
      }
      else {
        debug.error("error", "Invalid callback format.", history.location);
        throw "Invalid callaback format";
      }
    });
  }

  /**
   * Obtiene y aplica a la sesión el nombre de la máquina que llamó al backend dada su IP.
   * @param urlParams De los parámetros de la URL se sacará el 'state'.
   * @returns Devuelve la URL a la que redireccionar.
   */
  const loginIpAddress = async (urlParams: URLSearchParams): Promise<string | null> => {
    let returnUrl = urlParams.get("state");

    return await
      LoginService.createTokenIpAddress()
        .then(async result => {
          await performLogin(result);
          return returnUrl;
        });
  }

  /**
   * Aplica a la sesión el usuario indicado.
   * @param result Información sobre el usuario.
   */
  const performLogin = async (result: AuthorityToken) => {
    console.log(result);
    store.dispatch(setToken(result));

    /*
    return await
      LoginService.getCurrentCredetials(result.accessToken)
        .then(response => {
          store.dispatch(setCurrentCredentials(response));
        });
    */

    store.dispatch(setCurrentCredentials({
      nameType: result.nameType,
      nameId: result.nameId
    }));
  }

  useEffect(() => {
    let search = (history.location.search ||
      (history.location.hash ? `?${history.location.hash.substring(1)}` : "")
    );

    callback(search);
  }, [provider]);

  useEffect(() => {
    console.log("error", errorData);
  }, [location]);

  return (
    (errorData ?
      <Grid container direction={'column'} spacing={2}>
        <Grid item spacing={2} />
        <Grid item spacing={2}>
          <Typography variant="h3">Error loging in</Typography>
        </Grid>
        <Grid item spacing={2}><Typography>{errorData.message}</Typography></Grid>
        <Grid item spacing={2} style={{ padding: '20px 50px' }}>
          <Grid container direction={'row'} alignItems={'center'} spacing={2}>
            <Button style={{ textAlign: 'left' }} onClick={() => setErrorDetailsVisible(!errorDetailsVisible)}>
              Details
              {(errorDetailsVisible ? <ArrowDropDown style={{ fill: "azure" }} /> : <ArrowDropUp style={{ fill: "azure" }} />)}
            </Button>
          </Grid>
          {errorDetailsVisible ?
            <Typography variant="body1" style={{ whiteSpace: 'pre-wrap', fontFamily: 'monospace', textAlign: 'left', fontSize: '11px' }}>
              {JSON.stringify(errorData.content, null, 2)}
            </Typography>
            : null
          }
        </Grid>
      </Grid>
      :
      <Box sx={{
        display: 'flex',
        justifyContent: 'center', // Centra horizontalmente
        alignItems: 'center',     // Centra verticalmente
        height: '100vh',          // Ocupa el 100% de la altura de la pantalla
      }}>
        <CircularProgress />
      </Box>
    )
  );
}

export default OAuth2Callback;
export { OAuth2Callback };