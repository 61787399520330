import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { MainFrame } from '../../components';
import { CommonHelper, MsalLoginHelper, HmyLoginHelper } from '../../helpers';
import { CommonService, LoginService } from '../../services';
import { CurrentCredentials } from '../../types';
import { PublicClientApplication } from '@azure/msal-browser';
import store, { getStore, setToken, setCurrentCredentials } from '../../store';
import debug from '../../helpers/debug';
import logo from '../../assets/logo.svg';


const HomePage = (): JSX.Element => {
  const MSAL_PROFILE = "msal";

  const history = useHistory();
  const dispatch = useDispatch();
  const { authority } = getStore(useSelector);
  const [credentials, setCredentials] = useState<CurrentCredentials | null>(null);
  const [ntlmLoginAvailable, setNtlmLoginAvailable] = useState<boolean>();
  const [certLoginAvailable, setCertLoginAvailable] = useState<boolean>();
  const [msalLoginAvailable, setMsalLoginAvailable] = useState<boolean>();
  const [basicLoginAvailable, setBasicLoginAvailable] = useState<boolean>();
  const [ipAddressLoginAvailable, setIpAddressLoginAvailable] = useState<boolean>();


  const getVersion = async () => {
    await CommonService.getVersion();
  }

  const getUrl = (location: any): string => {
    return `${location.pathname}${location.search}${location.hash}`
  }

  const loginNtlm = async () => {
    await HmyLoginHelper.loginNtlm(getUrl(history.location));
  }

  const loginCert = async () => {
    await HmyLoginHelper.loginCert(getUrl(history.location));
  }

  const loginMsal = async () => {
    await MsalLoginHelper.loginMsal(MSAL_PROFILE, getUrl(history.location));
  }

  const loginBasic = async () => {
    await HmyLoginHelper.loginBasic(getUrl(history.location));
  }

  const loginIpAddress = async () => {
    await HmyLoginHelper.loginIpAddress(getUrl(history.location));
  }

  const logout = async () => {
    dispatch(setCurrentCredentials(null));
    dispatch(setToken(null));
  }

  useEffect(() => {
    debug.log("Creating component", "Home");

    LoginService.getSettingsNtlm()
      .then(_ => setNtlmLoginAvailable(true))
      .catch(_ => setNtlmLoginAvailable(false));
    LoginService.getSettingsCert()
      .then(_ => setCertLoginAvailable(true))
      .catch(_ => setCertLoginAvailable(false));
    LoginService.getSettingsMsal(MSAL_PROFILE)
      .then(_ => setMsalLoginAvailable(true))
      .catch(_ => setMsalLoginAvailable(false));
    LoginService.getSettingsBasic()
      .then(_ => setBasicLoginAvailable(true))
      .catch(_ => setBasicLoginAvailable(false));
    LoginService.createTokenIpAddress() // TODO: debería depender de si está habilitado el DNS reverso en esa red.
      .then(_ => setIpAddressLoginAvailable(true))
      .catch(_ => setIpAddressLoginAvailable(false))
  }, []);

  useEffect(() => {
    setCredentials(store.getState().authority.currentCredentials);
  }, [authority]);

  return (
    <MainFrame headerTitle="Home" style={{ padding: '0px 0px' }} footerVisibility='static'>
      <p>
        Hello {credentials?.nameId ?? "Guest"}!
      </p>
      <img src={logo} className="app-logo" alt="logo" />
      <p>
        Welcome to demo site.
      </p>
      <button className="button-link app-link" onClick={() => getVersion()}>
        Get API version
      </button>
      <button className="button-link app-link" onClick={() => loginNtlm()} disabled={!ntlmLoginAvailable || !!credentials}>
        Login with NTLM
      </button>
      <button className="button-link app-link" onClick={() => loginCert()} disabled={!certLoginAvailable || !!credentials}>
        Login with CERT
      </button>
      <button className="button-link app-link" onClick={() => loginMsal()} disabled={!msalLoginAvailable || !!credentials}>
        Login with MSAL
      </button>
      <button className="button-link app-link" onClick={() => loginBasic()} disabled={!basicLoginAvailable || !!credentials}>
        Login with BASIC
      </button>
      <button className="button-link app-link" onClick={() => loginIpAddress()} disabled={!ipAddressLoginAvailable || !!credentials}>
        Login with IP Address
      </button>
      <button className="button-link app-link" onClick={() => logout()} disabled={!credentials}>
        Logout
      </button>
    </MainFrame>
  );
};

export { HomePage };
export default HomePage;