import HttpClient from './http-client';
import { CommonHelper } from '../helpers';
import { AuthorityToken, CurrentCredentials } from '../types';
import debug from '../helpers/debug';
import envar from '../environment-variables';

class LoginService extends HttpClient {
  public constructor() {
    super(envar.apiUrl as string);
  }

  public createToken = async (provider: string, code: string): Promise<AuthorityToken> => {
    return new Promise<AuthorityToken>((response, reject) => {
      this.instance.post<AuthorityToken>(`auth/credentials/${provider}`, { code },
        { headers: { 'Content-Type': 'application/json' } },
      )
        .then(result => {
          response(result.data);
        })
        .catch(err => {
          console.log(CommonHelper.summaryAxiosError(err));
          debug.error("createToken", provider, "fetch error:", CommonHelper.summaryAxiosError(err));
          reject(err);
        });
    });
  }
  
  public createTokenMsal = async (profile: string, code: string, redirectUri: string | URL, state: string | null): Promise<AuthorityToken> => {
    return new Promise<AuthorityToken>((response, reject) => {
      this.instance.post<AuthorityToken>(`auth/credentials/msal/${profile}`, { code, redirectUri, state },
        { headers: { 'Content-Type': 'application/json' } },
      )
        .then(result => {
          response(result.data);
        })
        .catch(err => {
          console.log(CommonHelper.summaryAxiosError(err));
          debug.error("createTokenMsal", "fetch error:", CommonHelper.summaryAxiosError(err));
          reject(err);
        });
    });
  }

  public createTokenIpAddress = async (): Promise<AuthorityToken> => {
    return new Promise<AuthorityToken>((response, reject) => {
      this.instance.post<AuthorityToken>(`auth/credentials/ipaddress`,
        { headers: { 'Content-Type': 'application/json' } },
      )
        .then(result => {
          response(result.data);
        })
        .catch(err => {
          console.log(CommonHelper.summaryAxiosError(err));
          debug.error("createTokenIpAddress", "fetch error:", CommonHelper.summaryAxiosError(err));
          reject(err);
        });
    });
  }

  public createTokenBasic = async (userName: string, password: string): Promise<AuthorityToken> => {
    return new Promise<AuthorityToken>((response, reject) => {
      this.instance.post<AuthorityToken>(`auth/credentials/basic`, { userName, password },
        { headers: { 'Content-Type': 'application/json' } },
      )
        .then(result => {
          response(result.data);
        })
        .catch(err => {
          console.log(CommonHelper.summaryAxiosError(err));
          debug.error("createTokenBasic", "fetch error:", CommonHelper.summaryAxiosError(err));
          reject(err);
        });
    });
  }

  public getCurrentCredetials = async (accessToken: string | null): Promise<CurrentCredentials> => {
    let rdo =
      await this.instance.get<CurrentCredentials>('auth',
        { headers: { 'Authorization': `Bearer ${accessToken}` } }
      )
        .then(response => {
          return response.data;
        })
        .catch(err => {
          debug.error("getCurrentCredentials", "fetch error:", CommonHelper.summaryAxiosError(err));
          return err;
        });
    return rdo;
  }


  public getSettingsNtlm = async (): Promise<{ oauth2Address: string, apiAddress: string }> => {
    return new Promise<{ oauth2Address: string, apiAddress: string }>((response, reject) => {
      this.instance.get<{ oauth2Address: string, apiAddress: string }>(`auth/login-url/ntlm`,
        { headers: { 'Content-Type': 'application/json' } },
      )
        .then(result => {
          response(result.data);
        })
        .catch(err => {
          console.log(CommonHelper.summaryAxiosError(err));
          debug.error("getSettingsNtlm", "fetch error:", CommonHelper.summaryAxiosError(err));
          reject(err);
        });
    });
  }

  public getSettingsCert = async (): Promise<{ oauth2Address: string, apiAddress: string }> => {
    return new Promise<{ oauth2Address: string, apiAddress: string }>((response, reject) => {
      this.instance.get<{ oauth2Address: string, apiAddress: string }>(`auth/login-url/cert`,
        { headers: { 'Content-Type': 'application/json' } },
      )
        .then(result => {
          response(result.data);
        })
        .catch(err => {
          console.log(CommonHelper.summaryAxiosError(err));
          debug.error("getSettingsCert", "fetch error:", CommonHelper.summaryAxiosError(err));
          reject(err);
        });
    });
  }

  public getSettingsMsal = async (provider: string): Promise<{ clientId: string, authority: string, authorizationEndPoint: string }> => {
    return new Promise<{ clientId: string, authority: string, authorizationEndPoint: string }>((response, reject) => {
      this.instance.get<{ clientId: string, authority: string, authorizationEndPoint: string }>(`auth/login-url/msal/${provider}`,
        { headers: { 'Content-Type': 'application/json' } },
      )
        .then(result => {
          response(result.data);
        })
        .catch(err => {
          console.log(CommonHelper.summaryAxiosError(err));
          debug.error("getSettingsMsal", "fetch error:", CommonHelper.summaryAxiosError(err));
          reject(err);
        });
    });
  }

  public getSettingsBasic = async (): Promise<{ oauth2Address: string, apiAddress: string }> => {
    return new Promise<{ oauth2Address: string, apiAddress: string }>((response, reject) => {
      this.instance.get<{ oauth2Address: string, apiAddress: string }>(`auth/login-url/basic`,
        { headers: { 'Content-Type': 'application/json' } },
      )
        .then(result => {
          response(result.data);
        })
        .catch(err => {
          console.log(CommonHelper.summaryAxiosError(err));
          debug.error("getSettingsBasic", "fetch error:", CommonHelper.summaryAxiosError(err));
          reject(err);
        });
    });
  }

}

const singleton = new LoginService();

export { singleton as LoginService };
export default singleton;