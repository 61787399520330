import { CommonService, LoginService } from '../services';
import { CommonHelper } from '.';
import store, { setToken, setCurrentCredentials } from '../store';
import { CurrentCredentials, AuthorityToken, Authority } from '../types';
import { PublicClientApplication } from '@azure/msal-browser';
import debug from './debug';

class HmyLoginHelper {

  /**
   * Redirecciona a la URL externa encargada del login con la autentificación integrada de windows en el proceo de oauth2.
   * @param location Url a la que debe ir tras completar el proceso de autentificación.
   */
  public loginNtlm = async (location: string | null) => {
    let redirect_uri = encodeURIComponent(`${window.location.origin}/oauth2-callback/ntlm`);
    let loginUrl = (await LoginService.getSettingsNtlm()).oauth2Address;
    let path = loginUrl ? CommonHelper.combineUrl(loginUrl, `authorize?response_type=code&redirect_uri=${redirect_uri}`) : "error";

    if (location) {
      path = `${path}&state=${encodeURIComponent(location)}`
    }
    window.location.assign(path);
  }

  /**
   * Redirecciona a la URL externa encargada del login con la autentificación por certificado en el proceo de oauth2.
   * @param location Url a la que debe ir tras completar el proceso de autentificación.
   */
  public loginCert = async (location: string | null) => {
    let redirect_uri = encodeURIComponent(`${window.location.origin}/oauth2-callback/cert`);
    let loginUrl = (await LoginService.getSettingsCert()).oauth2Address;
    let path = loginUrl ? CommonHelper.combineUrl(loginUrl, `authorize?response_type=code&redirect_uri=${redirect_uri}`) : "error";

    if (location) {
      path = `${path}&state=${encodeURIComponent(location)}`
    }
    window.location.assign(path);
  }

  /**
   * Redirecciona a la URL externa encargada del login con la autentificación mediante el formulario login+password en el proceo de oauth2.
   * @param location Url a la que debe ir tras completar el proceso de autentificación.
   */
  public loginBasic = async (location: string | null) => {
    let redirect_uri = encodeURIComponent(`${window.location.origin}/oauth2-callback/basic`);
    let loginUrl = (await LoginService.getSettingsBasic()).oauth2Address;
    let path = loginUrl ? CommonHelper.combineUrl(loginUrl, `authorize?response_type=code&redirect_uri=${redirect_uri}`) : "error";

    if (location) {
      path = `${path}&state=${encodeURIComponent(location)}`
    }
    window.location.assign(path);
  }

  /**
   * Redirecciona directamente a la página de oauth2.
  */
  public loginIpAddress = async(location: string | null) => {
    let path = `${window.location.origin}/oauth2-callback/ipaddress`;

    if (location) {
      path = `${path}?state=${encodeURIComponent(location)}`
    }
    window.location.assign(path);
  }

}

const singleton = new HmyLoginHelper();

export default singleton;
export { singleton as HmyLoginHelper };