import { AuthorityTypes, SET_CURRENT_CREDENTIALS, SET_TOKEN } from '../types/authority-types';
import { AuthorityToken, CurrentCredentials } from '../../types';

export const setCurrentCredentials = (currentUser: CurrentCredentials | null): AuthorityTypes => {
  return {
    type: SET_CURRENT_CREDENTIALS,
    payload: currentUser
  };
}

export const setToken = (token: AuthorityToken | null): AuthorityTypes => {
  return {
    type: SET_TOKEN,
    payload: token
  };
}
