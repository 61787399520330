import HttpClient from './http-client';
import { CommonHelper } from '../helpers';
import { AuthorityToken, CurrentCredentials } from '../types';
import debug from '../helpers/debug';
import envar from '../environment-variables';

class LoginMsalService extends HttpClient {
  public constructor() {
    super(envar.apiUrl as string);
  }

  public createToken = async (provider: string, code: string, state: string | null = null, redirect_uri: string): Promise<AuthorityToken> => {
    return new Promise<AuthorityToken>((response, reject) => {
      this.instance.post<AuthorityToken>(`auth/grantAccessToken`, { 
          provider, 
          accessCode: code, 
          state, 
          redirectUri: redirect_uri 
        },
        { headers: { 'Content-Type': 'application/json' } },
      )
        .then(rdo => {
          response(rdo.data);
        })
        .catch(err => {
          console.log(CommonHelper.summaryAxiosError(err));
          debug.error("createToken", "fetch error:", CommonHelper.summaryAxiosError(err));
          reject(err);
        });
    });
  }

  public getCurrentCredetials = async (accessToken: string | null): Promise<CurrentCredentials> => {
    let rdo =
      await this.instance.get<CurrentCredentials>('auth',
        { headers: { 'Authorization': `Bearer ${accessToken}` } }
      )
        .then(response => {
          return response.data;
        })
        .catch(err => {
          debug.error("getCurrentCredentials", "fetch error:", CommonHelper.summaryAxiosError(err));
          return err;
        });
    return rdo;
  }

  public getLoginUrlsAsync = async (provider: string): Promise<{ clientId: string, authority: string, authorizationEndPoint: string, msalApiAddress: string }> => {
    return await
      this.instance.get<{ clientId: string, authority: string, authorizationEndPoint: string, msalApiAddress: string }>(`auth/login-url/${provider}`)
        .then(response => {
          return response.data;
        })
        .catch(err => {
          debug.error("getLoginUrlsAsync", "fetch error:", CommonHelper.summaryAxiosError(err));
          return err;
        });
  }

  public getMsalVersion = async (provider: string): Promise<{ version: string }> => {
    let rdo = await this.getLoginUrlsAsync(provider);
    return await
      this.instance.get<{ version: string }>(CommonHelper.combineUrl(rdo.msalApiAddress, 'common/version'))
        .then(response => {
          return response.data;
        })
        .catch(err => {
          debug.error("getVersion", "fetch error:", CommonHelper.summaryAxiosError(err));
          return err;
        });
  }

}

const singleton = new LoginMsalService();

export { singleton as LoginMsalService };
export default singleton;